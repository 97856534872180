<template>
  <!-- 主要內容 -->
  <section v-if="!isLoading" class="container pb-8 ">
    <div class="row">
      <div class="col-12 col-md-8 mx-auto">
        <h2 class="font-weight-bold mb-3 mb-md-6">會員資訊</h2>

        <div class="form-group row mb-3">
          <!-- 帳號 -->
          <label class="col-4 col-md-3">
            會員帳號
          </label>
          <div class="col-8 col-md-9 mb-4">
            {{ MemberForm.memberAccount }}
          </div>
          <!-- 姓名 -->
          <label class="col-4 col-md-3">
            會員姓名
          </label>
          <div class="col-8 col-md-9 mb-4">{{ MemberForm.memberName }}</div>
          <!-- 收件人住址 -->
          <label class="col-4 col-md-3">
            收件人住址
          </label>
          <div class="col-8 col-md-6" style="display: flex; justify-content: space-between;">
            {{ AddrText }}
            <i
              :class="encryptModel & 1 ? 'fa fa-eye-slash' : 'fa fa-eye'"
              @click="handelSetEncryptModel(1)"></i>
          </div>
          <div class="col-12 col-md-3">
            <router-link
              v-if="MemberForm.buttonOpen & 1"
              class="btn btn-hover-primary text-white border-radius-l w-100"
              to="/member/modify"
            >
              <span class="small">修改會員資料</span>
            </router-link>
          </div>
        </div>

        <!-- 密碼 -->
        <!-- <div class="form-group row mb-3">
          <label class="col-4 col-md-3">
            會員密碼
          </label>
          <div class="col-8 col-md-6">
            ***
          </div>
          <div class="col-12 col-md-3">
            <router-link
              v-if="MemberForm.buttonOpen & 2"
              class="btn btn-hover-primary text-white border-radius-l w-100"
              to="/member/changePassword"
            >
              <span class="small">修改密碼</span>
            </router-link>
          </div>
        </div> -->

        <!-- 信箱 -->
        <div class="form-group row">
          <label class="col-4 col-md-3 col-form-label">
            會員信箱
          </label>
          <div class="col-8 col-md-6 col-form-label"
            style="display: flex; justify-content: space-between;">
            {{ MailText }}
            <i
              :class="encryptModel & 2 ? 'fa fa-eye-slash' : 'fa fa-eye'"
              @click="handelSetEncryptModel(2)"></i>
          </div>
          <div class="col-12 col-md-3">
            <button
              v-if="MemberForm.buttonOpen & 4"
              type="button"
              class="btn btn-hover-primary text-white border-radius-l w-100"
              @click="OpenModal(1)"
            >
              <span class="small">驗證信箱</span>
            </button>
          </div>
        </div>

        <!-- 手機 -->
        <div class="form-group row">
          <label class="col-4 col-md-3 col-form-label">
            手機號碼
          </label>
          <div class="col-8 col-md-6 col-form-label"
            style="display: flex; justify-content: space-between;">
            {{ MobileText }}
            <i
              :class="encryptModel & 4 ? 'fa fa-eye-slash' : 'fa fa-eye'"
              @click="handelSetEncryptModel(4)"></i>
          </div>
          <div class="col-12 col-md-3 mb-2">
            <button
              v-if="MemberForm.buttonOpen & 8"
              type="button"
              class="btn btn-hover-primary text-white border-radius-l w-100"
              @click="OpenModal(2)"
            >
              <span class="small">{{ PhoneBtn }}</span>
            </button>
          </div>
        </div>

        <!-- 密碼 -->
        <!-- <div class="form-group row mb-3">
          <label class="col-4 col-md-3">
            綁定Line帳戶
          </label>
          <div class="col-8 col-md-6">
          <span
              class="badge badge-success"
              :class="{ 'badge-danger': MemberForm.isBindingLine == 0 }"
            >
              {{ LineBindingStatus }}</span>
          </div>
          <div class="col-12 col-md-3">
            <button
              v-if="MemberForm.buttonOpen & 16"
              class="btn btn-hover-primary text-white border-radius-l w-100"
              @click="HandleBindingLine"
            >
              <span class="small">綁定Line帳號</span>
            </button>
          </div>
        </div> -->

        <!-- 學歷資料 -->
        <el-form
          style="width: 100%;"
          :model="SchoolForm"
          label-position="top"
          :rules="SchoolRules"
          ref="SchoolForm"
          class="cs-form"
          data-style="2"
          @submit.prevent.native
        >
          <!-- 是否為在校生 -->
          <div class="form-group row mb-3">
            <label
              :class="[
                'col-4 col-md-3',
                { 'necessary': SchoolModify }
              ]"
            >
              是否為在校生
            </label>
            <template v-if="SchoolModify">
              <el-form-item
                prop="inSchool"
                class="col-8 col-md-6"
              >
                <el-radio-group
                  v-model="SchoolForm.inSchool"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </template>

            <div v-else class="col-8 col-md-6">
              {{ MemberForm.inSchool === 1 ? '是' : '否' }}
            </div>
          </div>

          <!-- 目前最高學歷 -->
          <div
            class="form-group row mb-3"
          >
            <label
              :class="[
                'col-4 col-md-3',
                { 'necessary': SchoolModify }
              ]"
            >
              目前最高學歷
            </label>

            <template v-if="SchoolModify">
              <el-form-item
                prop="schoolId"
                class="col-12 col-md-3 mb-4"
              >
                <el-select
                  :style="`display: block;`"
                  v-model="SchoolForm.schoolId"
                  @change="SetSchoolDept('SchoolForm')"
                  filterable
                  placeholder="請選擇學校"
                >
                  <el-option
                    v-for="item in SchoolOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                prop="schoolDeptId"
                class="col-12 col-md-3 mb-4"
              >
                <el-select
                  :style="`display: block;`"
                  v-model="SchoolForm.schoolDeptId"
                  @change="SetZipCode()"
                  filterable
                  placeholder="請選擇系所"
                >
                  <el-option
                    v-for="item in SchoolDeptOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                v-if="SchoolForm.inSchool"
                prop="schoolYearId"
                class="col-12 col-md-3 mb-4"
              >
                <el-select
                  :style="`display: block;`"
                  v-model="SchoolForm.schoolYearId"
                  @change="SetZipCode()"
                  placeholder="請選擇年級"
                >
                  <el-option
                    v-for="item in SchoolYearOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>

            <div v-else class="col-8 col-md-6">
              {{ SchoolText }}
            </div>
          </div>

          <!-- 送出按紐 -->
          <div
            v-if="SchoolModify"
            class="form-group row mb-6 mb-md-8"
          >
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="ModifyMemberSchool"
              >
                <span class="small">確認送出</span>
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="verificationModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <el-form
            style="width: 100%;"
            :model="VerificationForm"
            label-position="top"
            :rules="VerificationRules"
            ref="VerificationForm"
            class="cs-form"
            data-style="2"
            @submit.prevent.native
          >
            <div class="modal-body p-3 p-md-6">
              <h5 class="modal-title mb-3" id="exampleModalCenterTitle">
                {{ VerificationTitle }}
              </h5>
              <!-- 信箱 -->
              <div class="form-group row ">
                <p class="col-12 col-md-9 form-text text-danger" v-show="VerificationType === 1">
                  *信箱驗證後將不能再修改
                </p>
                <el-form-item prop="data" class="col-12 col-md-8 mb-2">
                  <el-input
                    :class="`el-input__input`"
                    placeholder="信箱"
                    type="text"
                    id="data"
                    v-model="VerificationForm.data"
                  />
                </el-form-item>
                <div class="col-12 col-md-4 mb-2">
                  <button
                    type="button"
                    class="btn btn-hover-primary text-white border-radius-l w-100"
                    :disabled="TimerText !== '發送認證碼'"
                    @click="GetVerification()"
                  >
                    {{ TimerText }}
                  </button>
                </div>
              </div>
              <!-- 認證碼 -->
              <div class="form-group row ">
                <el-form-item prop="verificationCaptchaCode" class="col-12 col-md-8 mb-2">
                  <el-input
                    :class="`el-input__input`"
                    placeholder="請輸入認證碼"
                    type="text"
                    id="verificationCaptchaCode"
                    v-model="VerificationForm.verificationCaptchaCode"
                  />
                </el-form-item>
                <div class="col-12 col-md-4 mb-2">
                  <button
                    type="button"
                    class="btn btn-hover-primary text-white border-radius-l w-100"
                    @click="HandleVerification()"
                  >
                    認 證
                  </button>
                </div>
              </div>
            </div>
          </el-form>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="NoticeDialog" class="el-dialog__wrapper--modify">
      <span>請驗證『會員信箱』與『手機號碼』</span>
      <p class="text-danger">
        *未驗證前無法操作各會員相關功能
      </p>
      <small id="emailHelp" class="form-text text-danger">
        (上課去、課程影片、密碼修改、基本資料修改、訂單查詢、購物車)
      </small>

      <span slot="footer" class="dialog-footer">
        <button type="button" class="btn btn-primary" @click="NoticeDialog = false">
          關閉
        </button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import SchoolFunc from '@/lib/school';
import { GetCountyList, GetCityList, GetCityByZipCode } from '@/lib/zipcode';
import {
  GetMembetInfoData,
  ModifyMemberMail,
  ModifyMemberPhone,
  BindMemberLine,
  GetSchool,
  GetSchoolDept,
  GetSchoolYear,
  ModifyMemberSchool,
} from '@/api/MemberApi';
import { MemberSendVerification } from '@/api/BaseApi';

/* global $ */
export default {
  data() {
    // 建立你的初始化 model data
    return {
      NoticeDialog: false,
      MemberForm: {
        memberId: 0,
        memberAccount: '',
        memberName: '',
        memberMail: '',
        memberPhone: '',
        memberZipCode: '',
        memberCounty: '',
        memberCity: '',
        memberAddress: '',
        isBindingMail: 0,
        isBindingPhone: 0,
        inSchool: 0,
        schoolId: 0,
        schoolDeptId: 0,
        schoolYearId: 0,
        schoolName: '',
        schoolDeptName: '',
        schoolYearName: '',
        buttonOpen: 0,
      },

      // 取得驗證碼/驗證
      VerificationForm: {
        data: '',
        verificationCaptchaCode: '',
        VerificationGuid: '',
      },
      VerificationRules: {
        data: [
          { required: true, message: '請輸入信箱', trigger: 'blur' },
          { type: 'email', message: '請輸入正確的信箱格式', trigger: ['blur', 'change'] },
        ],
        memberPhone: [{ required: true, message: '請輸入手機號碼', trigger: 'blur' }],
        memberCounty: [{ required: true, message: '請選擇縣市', trigger: 'change' }],
        memberCity: [{ required: true, message: '請選擇地區', trigger: 'change' }],
        memberAddress: [{ required: true, message: '請輸入地址', trigger: 'blur' }],
        verificationCaptchaCode: [{ required: true, message: '請輸入驗證碼', trigger: 'blur' }],
      },
      CountyOption: [],
      CityOption: [],
      Timer: '',
      TimerText: '發送認證碼',
      VerificationType: 0,
      encryptModel: 7,

      SchoolFunc: {
        GetSchool,
        GetSchoolYear,
        GetSchoolDept,
      },
      SchoolForm: {
        inSchool: '',
        schoolId: '',
        schoolDeptId: '',
        schoolYearId: '',
      },
      SchoolRules: {
        inSchool: [{ required: true, message: '請選擇是否為在校生', trigger: ['blur', 'change'] }],
        schoolId: [{ required: true, message: '請選擇學校', trigger: ['blur', 'change'] }],
        schoolDeptId: [{ required: true, message: '請選擇系所', trigger: ['blur', 'change'] }],
        schoolYearId: [{ required: true, message: '請選擇年級', trigger: ['blur', 'change'] }],
      },
      SchoolOption: [],
      SchoolDeptOption: [],
      SchoolYearOption: [],
      isLoading: true,
    };
  },
  computed: {
    LineBindingStatus() {
      return this.MemberForm.isBindingLine ? '已綁定' : '未綁定';
    },
    MailStatus() {
      return this.MemberForm.isBindingMail ? '已驗證' : '未驗證';
    },
    PhoneStatus() {
      return this.MemberForm.isBindingPhone ? '已驗證' : '未驗證';
    },
    PhoneBtn() {
      return this.MemberForm.isBindingPhone ? '修改手機' : '驗證手機';
    },
    VerificationTitle() {
      return this.VerificationType === 1 ? '會員信箱驗證' : '會員手機驗證';
    },
    MobileText() {
      // eslint-disable-next-line no-bitwise
      if ((this.encryptModel & 4) === 4) {
        return this.encryptString(this.MemberForm.memberPhone, 4, 3);
      }
      return this.MemberForm.memberPhone;
    },
    MailText() {
      // eslint-disable-next-line no-bitwise
      if ((this.encryptModel & 2) === 2) {
        const maskMail = this.MemberForm.memberMail.replace(/^(.)(.*)(.@.)(.*)(.com)$/,
          (_, a, b, c, d, e) => a + b.replace(/./g, '*') + c + d.replace(/./g, '*') + e);
        return maskMail;
      }
      return this.MemberForm.memberMail;
    },
    AddrText() {
      const mInfo = this.MemberForm;
      const addStr = `${mInfo.memberZipCode} ${mInfo.memberCounty} ${mInfo.memberCity}`;
      // eslint-disable-next-line no-bitwise
      if ((this.encryptModel & 1) === 1) {
        return `${addStr} ${this.encryptString(mInfo.memberAddress, 0, mInfo.memberAddress.length - 1)}`;
      }
      return `${addStr} ${mInfo.memberAddress}`;
    },
    SchoolText() {
      const mInfo = this.MemberForm;
      const schoolStr = `${mInfo.schoolName} ${mInfo.schoolDeptName} ${mInfo.schoolYearName}`;

      return schoolStr;
    },
    SchoolModify() {
      // eslint-disable-next-line no-bitwise
      return (this.MemberForm.buttonOpen & 32);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'SchoolForm.inSchool': function () {
      if (this.SchoolForm.inSchool === 0) {
        this.changeInSchool('SchoolForm');
      }
    },
  },
  created() {
    // 一開始執行的地方
    this.init();
  },
  beforeRouteLeave(to, form, next) {
    this.ClearTimer();
    next();
  },
  methods: {
    ...SchoolFunc,
    async  init() {
    // 一開始執行的地方
      await this.GetMemberData();

      this.SetCountyList();
      this.SetCityList(0);
      this.SetZipCode();

      this.GetSchoolList();
      this.GetSchoolYearList();

      this.NoticeDialog = !this.$IsBinding;

      this.isLoading = false;
    },
    encryptString(str, start, length) {
      if (str === '') {
        return '';
      }
      const text = str.replace(str.substr(start, length), '*'.repeat(length));
      return text;
    },
    SetCountyList() {
      this.CountyOption = GetCountyList();
    },
    SetCityList(clear) {
      this.CityOption = GetCityList(this.MemberForm.memberCounty);
      if (clear === 1) {
        this.MemberForm.memberCity = '';
        this.MemberForm.memberZipCode = '';
      } else {
        const data = this.CityOption.filter((item) => this.MemberForm.memberCity === item.label);
        if (!data[0]) {
          this.MemberForm.memberCity = '';
          this.MemberForm.memberZipCode = '';
        }
      }
    },
    SetZipCode() {
      const data = this.CityOption.filter((item) => this.MemberForm.memberCity === item.label);
      if (data[0]) {
        this.MemberForm.memberZipCode = data[0].value;
      } else {
        this.MemberForm.memberZipCode = '';
      }
    },
    // 你的 js function
    GetMemberData() {
      return GetMembetInfoData().then((response) => {
        if (response.data.statusCode === 0) {
          this.MemberForm = response.data.data;
          if (this.MemberForm.memberCity === null || this.MemberForm.memberCity === '') {
            this.MemberForm.memberCity = GetCityByZipCode(this.MemberForm.memberZipCode);
          }
        }
      });
    },

    handelSetEncryptModel(val) {
      // eslint-disable-next-line no-bitwise
      this.encryptModel ^= val;
    },
    // 驗證使用
    OpenModal(type) {
      this.VerificationType = type;
      this.$refs.VerificationForm.resetFields();

      if (type === 1) {
        this.VerificationForm.data = this.MemberForm.memberMail;
        this.VerificationRules.data = [
          { required: true, message: '請輸入信箱', trigger: 'blur' },
          { type: 'email', message: '請輸入正確的信箱格式', trigger: ['blur', 'change'] },
        ];
      } else {
        this.VerificationForm.data = this.MemberForm.memberPhone;
        this.VerificationRules.data = [
          { required: true, message: '請輸入手機號碼', trigger: 'blur' },
          { min: 10, trigger: ['blur', 'change'], message: '手機號碼必須為10碼' },
          {
            pattern: /^09[0-9]{8}$/,
            message: '請輸入正確的手機號碼',
            trigger: 'blur',
          },
        ];
      }
      $('#verificationModal').modal('show');
    },
    GetVerification() {
      this.$refs.VerificationForm.validateField('data', (error) => {
        if (!error) {
          const postData = {
            VerificationType: this.VerificationType,
            VerificationData: this.VerificationForm.data,
          };
          MemberSendVerification(postData).then((response) => {
            if (response.data.statusCode === 0) {
              this.StatrTimer();
              this.VerificationForm.VerificationGuid = response.data.data.verificationGuid;
            }
          });
        }
      });
    },
    StatrTimer() {
      const vm = this;
      const end = vm.$moment(new Date()).add(1, 'minutes');
      this.TiemrFunc(end);
      this.Timer = setInterval(() => this.TiemrFunc(end), 1000);
    },
    ClearTimer() {
      clearInterval(this.Timer);
    },
    TiemrFunc(end) {
      const now = new Date();
      const timeLeft = this.$moment(end).diff(now);
      const timeLeftFormat = this.$moment(timeLeft).format('mm:ss');
      if (timeLeft <= 0) {
        this.ClearTimer();
        this.TimerText = '發送認證碼';
      } else {
        this.TimerText = `${timeLeftFormat}`;
      }
    },
    HandleVerification() {
      let VerificationFunc = ModifyMemberMail;
      if (this.VerificationType === 2) {
        VerificationFunc = ModifyMemberPhone;
      }

      this.$refs.VerificationForm.validate((valid) => {
        if (valid) {
          VerificationFunc(this.VerificationForm).then((response) => {
            if (response.data.statusCode === 0) {
              const { jwt } = response.data.data;

              if (jwt !== null && jwt !== '') {
                const { ExpTime } = JSON.parse(
                  decodeURIComponent(
                    escape(
                      window.atob(
                        jwt
                          .split('.')[1]
                          .replace(/-/g, '+')
                          .replace(/_/g, '/'),
                      ),
                    ),
                  ),
                );
                const d = new Date(ExpTime);
                document.cookie = `token=${jwt}; expires=${d};path=/`;
                this.$setUserAccount();
              }
              this.$message({
                showClose: true,
                type: 'success',
                message: `${this.VerificationTitle}成功`,
              });
              this.ClearTimer();
              this.TimerText = '發送認證碼';
              this.$nextTick(() => {
                this.GetMemberData();
              });
              $('#verificationModal').modal('hide');
            }
          });
        }
      });
    },
    HandleBindingLine() {
      BindMemberLine({
        socialNetwork: 1, // 1:LINE
      }).then((response) => {
        if (response.data.statusCode === 0) {
          // eslint-disable-next-line no-restricted-globals
          location.href = response.data.data;
        }
      });
    },
    ModifyMemberSchool() {
      // 儲存按鈕
      this.$refs.SchoolForm.validate(async (valid) => {
        if (valid) {
          const form = this.SchoolForm;
          const postData = this._.cloneDeep(form);

          postData.schoolId = form.schoolId === '' ? 0 : form.schoolId;
          postData.schoolDeptId = form.schoolDeptId === '' ? 0 : form.schoolDeptId;
          postData.schoolYearId = form.schoolYearId === '' ? null : form.schoolYearId;

          ModifyMemberSchool(postData).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '修改成功',
              });
              this.init();
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 80%;

  &__header {
    border-bottom: 1px solid #ececec;
  }
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__body {
    font-size: 1rem;
    @media (min-width: 575.98px) {
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }
}
::v-deep.el-form-item {
  &:not(.is-error) {
    margin-bottom: 0;
  }
}
::v-deep .el-radio {
  &__inner {
    border: 1px solid #174e5f;
  }

  &__label {
    color: #174e5f;
  }

  &__input {
    transform: scale(1.2);

    &.is-checked  {
      .el-radio__inner {
          border-color: #174e5f;
          background: #174e5f;
      }

      + .el-radio__label {
        color: #174e5f;
      }
    }
  }
}
</style>
